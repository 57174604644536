import * as React from "react"
import '../styles/global.css'
import Mp4 from "../components/Mp4"

const IndexPage = () => {

  return (
    <main className="text-base bg-gold flex justify-center items-center">
      <div className="iphone-outer">
        <div className="iphone-inner">
          <div className="activity-wrapper">
            <Mp4 src="/051322_Trophy_Loop_V01_1.mp4" bg="gold" loop={true} hideNextButton={true} />
          </div>
        </div>
      </div>
    </main>
  )
}

export default IndexPage
